(function(){
        try {
            console.log("----> cmp_nav_header");

            let stickyAnchor = $('.sticky-anchor'),
                contactForm = $('.cmp_contact_form');

            let hasSubMenu = stickyAnchor.length > 0 ? true : false;
            let hasContactForm = contactForm.length > 0 ? true : false;

            console.log(' HAS FORM ------- ', hasContactForm);

            let header = $('.navigation.isDesktop'),
                slidesContainer = $('#slides-container'),
                stickyMenu = $('.sticky-menu'),
                stickySubMenu = $('.sticky-sub-menu'),
                contactUsSmall = $('.nav.small').find('.contactUsSmall'),
                contactUs = $('.contactUs'),
                stickyMenuBg = stickySubMenu.find('.full-width-background'),
                stickyMenuWrapper = stickySubMenu.find('.sticky-sub-menu-wrapper'),
                anchor = hasSubMenu ? stickyAnchor.offset().top - header.height() : null,
                stickyMenuItem = $('li', '.sticky-menu'),
                navHeight = 200;

            const clickHandler = (target) =>{
                let navigationHeight = getNavHeight() + stickySubMenu.height();
                let posTop;

                if(hasContactForm == false) {
                    window.$methods.handleRequest('contact');
                } else {
                    if($(target).hasClass('contactUs') || $(target).hasClass('contactUsSmall')) {
                        posTop = $('.cmp_contact_form').offset().top;
                    } else {
                        let index = parseInt($(target).data('index'));
                        let separator = $('.cmp_nav_separator')[index];
                        posTop = $(separator).offset().top;
                    } 
                    let top = posTop - navigationHeight;
                    $('html,body').animate({
                        scrollTop: top
                    }, 'slow');
                }

            }

            const getNavHeight = () => {
                let mainNavHeight = $('.navigation.isDesktop').outerHeight();
                if(mainNavHeight > 0   && $('.navigation.isDesktop').css("display") !== 'none') {
                    return mainNavHeight;
                }else{
                    return $('.nav-header').outerHeight();
                }
            };

            if (hasSubMenu) {
                $('.cmp_nav_separator').each((index, val) => {
                    let labelData = $(val).data().label;
                    let navScroll = $(val).offset().top - navHeight;
                    if (labelData != "Contact Form") {              
                        $('.sticky-menu', '.sticky-sub-menu').append(`<li class="button nav-link sub-nav" data-index=${index}>${labelData}<li>`);
                    }
                });

                stickySubMenu.find('.button').on('click', (e) => {
                    clickHandler(e.target);
                });
            } 

            /* fix for gray background when no items are present */
            if(!stickyMenu[0] || stickyMenu[0].children.length == 0){
                stickyMenuBg[0].classList.add("clear-no-items");
                stickyMenu[0].classList.add("clear-no-items");
                stickySubMenu[0].classList.add("clear-no-items");
            }

            contactUsSmall.on('click', (e) => {
                e.preventDefault();
                clickHandler(e.target);
            });

            contactUs.on('click', (e) => {
                e.preventDefault();
                clickHandler(e.target);
            });

            $(window).scroll(function(){

                let scroll = $(window).scrollTop();
                if (scroll >= 38){
                    header.addClass('sticky');
                    slidesContainer.addClass('sticky-on');
                    contactUs.css('display', 'block');
                } else {
                    header.removeClass('sticky');
                    slidesContainer.removeClass('sticky-on');
                    contactUs.css('display', 'none');
                } 
                
                if(anchor != 0 && anchor <= scroll && hasSubMenu) {
                    stickySubMenu.addClass('sticky-on');
                } else {
                    stickySubMenu.removeClass('sticky-on');
                }
            });
        }
        catch (err) {
            console.warn("cmp_nav_header: ", err);
        }


    })();

(function(){
        try {

            //console.log("----> cmp_nav_footer");
        }

        catch (err) {
            console.warn("cmp_nav_footer: ", err);
        }
    })();

(function(){

    try {

        console.log("----> cmp_nav_header");

        let contactUs = $('.cmp_nav_mobile').find('.contactMob');
        let btn = contactUs.find('a');

        btn.on('click', e => {
            e.preventDefault();
            window.$methods.handleRequest('contact');
        });
    }
    catch (err) {
        console.warn("cmp_nav_mobile: ", err);
    }
    
})();

(function(){
            try {

                console.log("----> cmp_modal_research");
        
                const endpoint = "/research_access_request";
                const scope = $('.cmp-modal-research .form');
        
                let hasErrors = [];
                let submited = false;
                let fields = $('.fields', scope);
                let submitBtn = $('#submit-research', scope);
                let submitBtnLabel = submitBtn.html();
                let textArea = $('.textarea', scope);
                let errMsg = $('.errorMsg ul', scope);
                let submitData;
        

                const loopFields = (callback) => {
                    let fields = $('.formfield', scope);
        
                    $.each(fields, (index, item)=> {
                        callback(item);
                    });
                }   
        
                const clearForm = () => {
                    loopFields((item)=> {
                        $(item).val('');
                    });
                    scope.find('.interest').val('default').change();
                }
        
                const hideErrors = () => {
                    errMsg.empty();
                    errMsg.parent().css('opacity', 0);
                };
        
                const showErrors = (errorsLst) => {
                    hideErrors();
                    if(errorsLst.length > 0) {
                        errMsg.parent().css('opacity', 1);
                        errorsLst.forEach( err => {
                            errMsg.append(`<li>${err}</li>`);
                        });
                    } else {
                        hideErrors();
                    }
                }
        
                const enableForm = () => {
        
                    loopFields((item)=> {
                        $(item).attr('disabled', false);
                    });
                    scope.find('.interest').attr('disabled', false);
                    scope.find('.message').attr('disabled', false);
        
                    addSubmitEvent();
                    submitBtn.removeClass('success');
                    submitBtn.html(submitBtnLabel);
                }
                
                const disbleForm = () => {
                
                    loopFields((item)=> {
                        if ($(item).hasClass('select')){
                            $(item).find('select').attr('disabled', 'disabled');
                        }
                        $(item).attr('disabled', 'disabled');
                    });
                    
                    submitBtn.off('click');
                }
        
                const validateData = () => {
                    hasErrors = [];
        
                    submitData = {
                        name: scope.find('.name').val().trim(),
                        company: scope.find('.company').val().trim(),
                        website: scope.find('.website').val().trim(),
                        phone: scope.find('.phone').val().trim(),
                        email: scope.find('.email').val().trim(),
                        message: scope.find('.message').val().trim(),
                    }
        
                    $.each(submitData, (key, value) => {
                        if(!isValid(key, value)){
                            console.error(`${key} is empty`);
                            hasErrors.push(key);
                        } else {
                            console.log(`${key}: ${value}` );
                        }
                    });
        
                    console.log(`has ${hasErrors.length} errors`);
                }
        
                const checkErrors = () => {
                    const fieldNames = [];
                    clearErrors();
        
                    if (hasErrors.length && submited) {
                        $.each(hasErrors, (key, val) =>{
                            let field = $(scope).find(`.${val}`);
                            field.addClass('fieldError');
                            fieldNames.push(field.attr('name'));
                        });
                    }
        
                    let errorMsgs = fieldNames.map(err =>{
                        return ` Invalid ${err}`
                    });              
        
                    if(submited) showErrors(errorMsgs);
                }
        
                const clearErrors = () => {
                    $.each(submitData, (key, val) =>{
                        $(scope).find(`.${key}`).removeClass('fieldError');
                    });
                }
        
                const submitSuccess = () => {
                    submitBtn.html('');
                    submitBtn.addClass('success');
                    hideErrors();                    
                    clearForm();
                    

                    setTimeout(()=>{
                        enableForm();
                        window.$methods.hideModal();
                    }, 1000);
                }
        
                const isValid = (key, value) => {
                    let valid = true;
        
                    if(value === null) { valid = false; }
                    if(value == "") { valid = false; }
                    if(value.length == 0) { valid = false; }
                    if(value.indexOf(`' '`) != -1) { valid = false; }
                    if(value.indexOf(`" "`) != -1) { valid = false; }
                    if(key.indexOf('email') != -1){
                        valid = validateEmail(value);
                    }
                    if(isBlank(value)){ valid = false; }
                
                    return valid;
                }
        
                const isBlank = (str) => {
                    return (!str || /^\s*$/.test(str));
                }
        
                const validateEmail = (email) => {
                    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                }
        
                fields.children().on('change', ()=> {
                    validateData();
                    checkErrors();
                });
        
                textArea.on('change', ()=> {
                    validateData();
                    checkErrors();
                });
        
                const addSubmitEvent = ()=> {
                    submitBtn.on('click', ()=> { 
                        console.log('submited')
                        validateData();
                        
                        if(hasErrors.length == 0){
                            disbleForm();
                            submitBtn.html('');
                            submitBtn.addClass('loading');

                                if(window.$data.lastSelecteResearchMeta){
                                    let _title = window.$data.lastSelecteResearchMeta[0];
                                    let _author = window.$data.lastSelecteResearchMeta[1];

                                    submitData.title = _title;
                                    submitData.author = _author;
                                }
        
                            $.post(endpoint, submitData, data => { 
                                submitBtn.removeClass('loading');
                                let {error, status} = data;
        
                                if (error == null && status == true) {
                                    submitSuccess();
                                } else {
                                    enableForm();
                                    showErrors(['Something went wrong, try again later!']);
                                    
                                    console.error(error);
                                }
                                console.log('form response --->', data);
                            });
                        } else {
                            submited = true;
                            checkErrors();
                        }
                    });
                }
        
                // ENABLE FORM AND CLICK EVENT
                enableForm();
            }
            catch (err) {
                console.warn("cmp_modal_research: ", err);
            }
        })();

(function(){
            try {
                console.log("----> cmp-modal-contact");
        
                const endpoint = "/contact";
                const scope = $('.cmp-modal-contact .form');
        
                let hasErrors = [];
                let submited = false;
                let fields = $('.fields', scope);
                let submitBtn = $('#submit', scope);
                let submitBtnLabel = submitBtn.html();
                let textArea = $('.textarea', scope);
                let errMsg = $('.errorMsg ul', scope);
                let submitData;
        
                const alreadySubmited = window.$methods.contactFormState('get');
                
                const loopFields = (callback) => {
                    let fields = $('.formfield', scope);
        
                    $.each(fields, (index, item)=> {
                        callback(item);
                    });
                }   
        
                const clearForm = () => {
                    loopFields((item)=> {
                        $(item).val('');
                    });
                    scope.find('.interest').val('default').change();
                }
        
                const hideErrors = () => {
                    errMsg.empty();
                    errMsg.parent().css('opacity', 0);
                };
        
                const showErrors = (errorsLst) => {
                    hideErrors();
                    if(errorsLst.length > 0) {
                        errMsg.parent().css('opacity', 1);
                        errorsLst.forEach( err => {
                            errMsg.append(`<li>${err}</li>`);
                        });
                    } else {
                        hideErrors();
                    }
                }
        
                const enableForm = () => {
        
                    loopFields((item)=> {
                        $(item).attr('disabled', false);
                    });
                    scope.find('.interest').attr('disabled', false);
                    scope.find('.message').attr('disabled', false);
        
                    addSubmitEvent();            
                    submitBtn.html(submitBtnLabel);
                }
                
                const disbleForm = () => {
                
                    loopFields((item)=> {
                        if ($(item).hasClass('select')){
                            $(item).find('select').attr('disabled', 'disabled');
                        }
                        $(item).attr('disabled', 'disabled');
                    });
                    
                    submitBtn.off('click');
                }
        
                const validateData = () => {
                    hasErrors = [];
        
                    submitData = {
                        interest: scope.find('.interest').val().trim(),
                        name: scope.find('.name').val().trim(),
                        company: scope.find('.company').val().trim(),
                        website: scope.find('.website').val().trim(),
                        phone: scope.find('.phone').val().trim(),
                        email: scope.find('.email').val().trim(),
                        message: scope.find('.message').val().trim()
                    }
        
                    $.each(submitData, (key, value) => {
                        if(!isValid(key, value)){
                            console.error(`${key} is empty`);
                            hasErrors.push(key);
                        } else {
                            console.log(`${key}: ${value}` );
                        }
                    });
        
                    console.log(`has ${hasErrors.length} errors`);
                }
        
                const checkErrors = () => {
                    const fieldNames = [];
                    clearErrors();
        
                    if (hasErrors.length && submited) {
                        $.each(hasErrors, (key, val) =>{
                            if (val === 'interest') {
                                $(scope).find(`.${val}`).parent().addClass('fieldError');
                            } 
                            let field = $(scope).find(`.${val}`);
                            field.addClass('fieldError');
                            fieldNames.push(field.attr('name'));
                        });
                    }
        
                    let errorMsgs = fieldNames.map(err =>{
                        return ` Invalid ${err}`
                    });              
        
                    if(submited) showErrors(errorMsgs);
                }
        
                const clearErrors = () => {
                    $.each(submitData, (key, val) =>{
                        if (key === 'interest') $(scope).find(`.${key}`).parent().removeClass('fieldError');
                        $(scope).find(`.${key}`).removeClass('fieldError');
                    });
                }
        
                const submitSuccess = () => {
                    submitBtn.html('');
                    submitBtn.addClass('success');
                    hideErrors();
                    disbleForm();
                    clearForm();

                    setTimeout(()=>{
                        window.$methods.hideModal();
                    }, 1000);
                }
        
                const isValid = (key, value) => {
                    let valid = true;
        
                    if(value === null) { valid = false; }
                    if(value == "") { valid = false; }
                    if(value.length == 0) { valid = false; }
                    if(value.indexOf(`' '`) != -1) { valid = false; }
                    if(value.indexOf(`" "`) != -1) { valid = false; }
                    if(key.indexOf('email') != -1){
                        valid = validateEmail(value);
                    }
                    if(isBlank(value)){ valid = false; }
                
                    return valid;
                }
        
                const isBlank = (str) => {
                    return (!str || /^\s*$/.test(str));
                }
        
                const validateEmail = (email) => {
                    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(email);
                }
        
                if (alreadySubmited){
                    submitSuccess();
                    return;
                }
        
                fields.children().on('change', ()=> {
                    validateData();
                    checkErrors();
                });
        
                textArea.on('change', ()=> {
                    validateData();
                    checkErrors();
                });
        
                const addSubmitEvent = ()=> {
                    submitBtn.on('click', ()=> { 
                        validateData();
                        
                        if(hasErrors.length == 0){
                            disbleForm();
                            submitBtn.html('');
                            submitBtn.addClass('loading');
        
                            $.post(endpoint, submitData, data => { 
                                submitBtn.removeClass('loading');
                                let {error, status} = data;
        
                                if (error == null && status == true) {
                                    submitSuccess();
                                    window.$methods.contactFormState('set', true);
                                } else {
                                    enableForm();
                                    showErrors(['Something went wrong, try again later!']);
                                    
                                    console.error(error);
                                }
                                console.log('form response --->', data);
                            });
                        } else {
                            submited = true;
                            checkErrors();
                        }
                    });
                }
        
                // ENABLE CLICK EVENT
                addSubmitEvent();
            }
            catch (err) {
                console.warn("cmp_modal_contact: ", err);
            }
        })();

(function(){
        try {
            console.log("----> cmp_contact_form");

            const endpoint = "/contact";
            const scope = $('.cmp_contact_form .form');

            let hasErrors = [];
            let submited = false;
            let fields = $('.fields', scope);
            let submitBtn = $('#submit', scope);
            let submitBtnLabel = submitBtn.html();
            let textArea = $('.textarea', scope);
            let errMsg = $('.errorMsg ul', scope);
            let thankYou = $('.thankYou', '.cmp_contact_form');
            let submitData;

            const alreadySubmited = window.$methods.contactFormState('get');
            
            const loopFields = (callback) => {
                let fields = $('.formfield', scope);

                $.each(fields, (index, item)=> {
                    callback(item);
                });
            }   

            const clearForm = () => {
                loopFields((item)=> {
                    $(item).val('');
                });
            }

            const hideErrors = () => {
                errMsg.empty();
                errMsg.parent().css('opacity', 0);
            };

            const showErrors = (errorsLst) => {
                hideErrors();
                if(errorsLst.length > 0) {
                    errMsg.parent().css('opacity', 1);
                    errorsLst.forEach( err => {
                        errMsg.append(`<li>${err}</li>`);
                    });
                } else {
                    hideErrors();
                }
            }

            const enableForm = () => {

                loopFields((item)=> {
                    $(item).attr('disabled', false);
                });
                scope.find('.interest').attr('disabled', false);
                scope.find('.message').attr('disabled', false);

                addSubmitEvent();            
                submitBtn.html(submitBtnLabel);
            }
            
            const disbleForm = () => {
            
                loopFields((item)=> {
                    if ($(item).hasClass('select')){
                        $(item).find('select').attr('disabled', 'disabled');
                    }
                    $(item).attr('disabled', 'disabled');
                });
                
                submitBtn.off('click');
            }

            const validateData = () => {
                hasErrors = [];

                submitData = {
                    interest: scope.find('.interest').val().trim(),
                    name: scope.find('.name').val().trim(),
                    company: scope.find('.company').val().trim(),
                    website: scope.find('.website').val().trim(),
                    phone: scope.find('.phone').val().trim(),
                    email: scope.find('.email').val().trim(),
                    message: scope.find('.message').val().trim()
                }

                $.each(submitData, (key, value) => {
                    if(!isValid(key, value)){
                        console.error(`${key} is empty`);
                        hasErrors.push(key);
                    } else {
                        console.log(`${key}: ${value}` );
                    }
                });

                console.log(`has ${hasErrors.length} errors`);
            }

            const checkErrors = () => {
                const fieldNames = [];
                clearErrors();

                if (hasErrors.length && submited) {
                    $.each(hasErrors, (key, val) =>{
                        if (val === 'interest') {
                            $(scope).find(`.${val}`).parent().addClass('fieldError');
                        } 
                        let field = $(scope).find(`.${val}`);
                        field.addClass('fieldError');
                        fieldNames.push(field.attr('name'));
                    });
                }

                let errorMsgs = fieldNames.map(err =>{
                    return ` Invalid ${err}`
                });              

                if(submited) showErrors(errorMsgs);
            }

            const clearErrors = () => {
                $.each(submitData, (key, val) =>{
                    if (key === 'interest') $(scope).find(`.${key}`).parent().removeClass('fieldError');
                    $(scope).find(`.${key}`).removeClass('fieldError');
                });
            }

            const showThankYouScreen = () => {
                let offset = 100;
                
                thankYou.css('display', 'block');
                
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".cmp_contact_form").offset().top - offset
                });

                setTimeout(()=>{
                    thankYou.addClass('visible');
                    $('.cmp_contact_form').addClass('thankVisible');
                }, 10);
            }

            const submitSuccess = () => {
                submitBtn.html('');
                submitBtn.addClass('success');
                hideErrors();
                disbleForm();
                clearForm();

                setTimeout(() => {
                    showThankYouScreen();
                }, 500);
            }

            const isValid = (key, value) => {
                let valid = true;

                if(value === null) { valid = false; }
                if(value == "") { valid = false; }
                if(value.length == 0) { valid = false; }
                if(value.indexOf(`' '`) != -1) { valid = false; }
                if(value.indexOf(`" "`) != -1) { valid = false; }
                if(key.indexOf('email') != -1){
                    valid = validateEmail(value);
                }
                if(isBlank(value)){ valid = false; }
            
                return valid;
            }

            const isBlank = (str) => {
                return (!str || /^\s*$/.test(str));
            }

            const validateEmail = (email) => {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }

            if (alreadySubmited){
                submitSuccess();
                return;
            }

            fields.children().on('change', ()=> {
                validateData();
                checkErrors();
            });

            textArea.on('change', ()=> {
                validateData();
                checkErrors();
            });

            const addSubmitEvent = ()=> {
                submitBtn.on('click', ()=> { 
                    validateData();
                    
                    if(hasErrors.length == 0){
                        disbleForm();
                        submitBtn.html('');
                        submitBtn.addClass('loading');

                        $.post(endpoint, submitData, data => { 
                            submitBtn.removeClass('loading');
                            let {error, status} = data;

                            if (error == null && status == true) {
                                submitSuccess();
                                window.$methods.contactFormState('set', true);
                            } else {
                                enableForm();
                                showErrors(['Something went wrong, try again later!']);
                                
                                console.error(error);
                            }
                            console.log('form response --->', data);
                        });
                    } else {
                        submited = true;
                        checkErrors();
                    }
                });
            }

            // ENABLE CLICK EVENT
            addSubmitEvent();
        }
        catch (err) {
            console.warn("cmp_contact_form: ", err);
        }
    })();

(function(){

        try {
            /**
            * Navigation related code
            */
            let navButtons = [];
            const StringOps = require("../custom_modules/string-ops");
            let stickySubMenu = $('.sticky-sub-menu');
            
            $('.cmp_nav_separator').each((index, val) => {
                let labelData = $(val).data();
                let navScroll = $(val).offset();
                if(labelData && labelData.label) labelData.label = StringOps.truncateWithEllipsis(labelData.label, 30);
                $('.nav-bar').append(`<button class="typo p3 company button link innerNavigation" data-index=${index}><span>${labelData.label}</span></button>`);
            });

            let getNavHeight = function(){
                let mainNavHeight = $('.navigation.isDesktop').outerHeight();
                if(mainNavHeight > 0   && $('.navigation.isDesktop').css("display") !== 'none') {
                    return mainNavHeight;
                }else{
                    return $('.nav-header').outerHeight();
                }
            };

            $('.innerNavigation').on('click', function(){
                let navigationHeight = getNavHeight() + stickySubMenu.height();
                let index = parseInt($(this).data('index'));
                let separator = $('.cmp_nav_separator')[index];
                let posTop = $(separator).offset().top;
                let top = posTop - navigationHeight;
                
                $('html,body').animate({
                    scrollTop: top
                }, 'slow');
             
            });
        }
        catch (err) {
            console.warn("cmp_hero_with_image: ", err);
        }
})();

(function(){
    try {
        //console.log("----> cmp_blade_title");
    }
    catch (err) {
        console.warn("cmp_blade_title: ", err);
    }
})();

(function(){
    try {
        $(".cmp_benefits_highlights").each(function(){

        }); 
    }
    catch (err) {
        console.warn("cmp_benefits_highlights: ", err);
    }

})();

(function(){
    try {
        //console.log("----> cmp_button_block_1");
    }
    catch (err) {
            console.warn("cmp_button_block_1: ", err);
        }
})();

(function(){
        try {
            let sectorStart = $('.cmp_sector');
            let sectorEnd = $('.cmp_sector_end');
            let container;
            let inBetween = [];

            if(!sectorEnd.length || !sectorStart.length) return;

            const grabBetween = (nextOf, section, indexStartClass = 0, indexEndClass = 0) => {
                let next = nextOf.next();
                section.push(next);

                if (!$(next).hasClass(indexEndClass)) {
                    grabBetween(next, section, indexStartClass, indexEndClass);
                } else {
                    next.remove();
                    section.pop();
                    inBetween.push(section);
                }

            }

            const addDelimitClass = (toWho, type) => {
                $.each(toWho, (index, val) => {

                    $(val).removeClass(`cmp_sector_${type}`);
                    $(val).addClass(`cmp_sector_${type}_${index}`);
                });
            }
            console.log('SECTOR TEST');

            addDelimitClass(sectorStart, 'start');
            addDelimitClass(sectorEnd, 'end');
            let section = [];
            
            $.each(sectorStart, (index, val) => {
                container = (`<div class="slice-container_${index}"></div>`);
                let indexEndClass =  `cmp_sector_end_${index}`;
                let indexStartClass =  `cmp_sector_end_${index}`;
                grabBetween($(val), section, indexStartClass, indexEndClass);
                section = [];
            });
        

            $.each(inBetween, (index, val) => {
                let parentByIdex =  $(`.cmp_sector_start_${index}`);
                
                $.each(val, (i, child) => {
                    let detached = child.detach();
                    detached.appendTo(parentByIdex.find('.slice-container'));
                });

            }); 

        }
        catch (err) {
            console.warn("cmp_sector_start: ", err);
        }

    })();

(function(){
    try {
        //console.log("----> cmp_nav_separator");
    }
    catch (err) {
        console.warn("cmp_nav_separator: ", err);
    }
})();

(function(){
    try {
       $('ul', '.cmp_page_feature').each(function(){
           let thisList = $(this);
           let contentLinks = thisList.find('a');
           if(contentLinks.length){
               thisList.addClass('link_list')
           }
       });
    }
    catch (err) {
        console.warn("cmp_page_feature: ", err);
    }
})();

(function(){
    try {
        $(".stat_block.sub_cmp").each(function(index, elem){
            
            let lines = elem.querySelectorAll(".line");
            let deltaPerLine = 1.75;
            let initValue = -23;

            for(let i = 0; i < lines.length; i++){
                let line = lines[i];
                let delta = '' + (initValue + (i * deltaPerLine));
                let cssString = `translateX(${delta}%) rotate(-45deg)`;
                line.style.transform = cssString;
            }
            

            //vertically center content inside parent
            let contentContainer = elem.querySelector(".content-container");
            let elemHeight = parseInt(getComputedStyle(elem).height);
            let contentHeight = parseInt(getComputedStyle(contentContainer).height);
            let vertOffset = (elemHeight - contentHeight )/ 2 - 20;
            contentContainer.style.marginTop = vertOffset + "px";

            //is the arrow container shown? If the arrow is hidden, let the stat container
            //occupy the full width of the parent container
            let isArrowHidden = elem.querySelector(".hide");
            if(isArrowHidden){
                let statContainer = elem.querySelector(".stat-container");
                statContainer.style.width = "100%";
            }

            //now let's try to ascertain the position of the data elements inside
            //the stat container. 
            let upper = elem.querySelector(".upper");
            let lower = elem.querySelector('.lower');
            let uWidth = parseFloat(getComputedStyle(upper).width);
            let lWidth = parseFloat(getComputedStyle(lower).width);
            
            //if the upper element is wider, we'll pick that to make our calcs
            let picked = (uWidth >= length) ? upper : lower;
            let elemWidth = parseInt(getComputedStyle(elem).width);
            let pickedWidth = parseInt(getComputedStyle(picked).width);
            let horOffset = (elemWidth - pickedWidth)/2;

            upper.style.marginLeft = horOffset + "px";
            lower.style.marginLeft = horOffset + "px";

            //now let's adjust the arrow based on this as well
            let arrowElem = elem.querySelector(".arrow-container");
            let wArrow = parseFloat(getComputedStyle(arrowElem).width);
            let arrowOffset = horOffset - wArrow;
            arrowElem.style.marginLeft = arrowOffset + "px";
        });
    }
    catch (err) {
        console.warn("stat_block: ", err);
    }
})();

(function(){
    try {
        //console.log("----> cmp_full_width_promo");
    }
    catch (err) {
        console.warn("cmp_full_width_promo: ", err);
    }
})();

(function(){
    const className = ".cmp-content-feature";

    const itemTemplateBasic = `
        <div class='lined-box event-article'>
            <div class='lines top'>
                <div class='line'></div>
                <div class='line'></div>
                <div class='line'></div>
            </div>
        </div>
        <div class='content'>
            <p class='type typo h5'>{{type}}</p>
            <p class='title typo h4'>{{title}}</p>
            <p class='date typo p5'>{{date}}</p>
            <p class='body typo p5'>{{body}}</p>
            <p class='meta typo p5' style='display:{{personDispStyle}}'>{{meta}}</p>
            <a href='{{url}}' style='display:{{linkDispStyle}}'><p class='link typo p5'>{{linkText}}</p></a>
        </div>
    `;

    const itemTemplateResearch = `
        <div class='lined-box'>
            <div class='lines top'>
                <div class='line'></div>
                <div class='line'></div>
                <div class='line'></div>
            </div>
        </div>
        <div class='content research'>
            <p class='type typo h5'>{{type}}</p>
            <p class='title typo h4'>{{title}}</p>
            <p class='date typo p5'>{{date}}</p>
            <div class='author-sector-cont'>
                <p class='meta typo p5' style='display:{{authorDispStyle}}'>{{author}}</p>
                <p class='meta typo p5' style='display:{{sectorDispStyle}}'>{{sector}}</p>
            </div>
            <a href='{{url}}' ><p class='link typo p5'>{{linkText}}</p></a>
        </div>
    `;

    const itemTemplateTransaction = `
        <div class='lined-box'>
            <div class='lines top'>
                <div class='line'></div>
                <div class='line'></div>
                <div class='line'></div>
            </div>
        </div>
        <div class='content transaction'>
            <img src='{{logo}}'></img>
            <p class='title typo h4'>{{amount}}</p>
            
            <div class='transaction-info-cont'>
                <p class='date typo p5'>{{date}}</p>
                <p class='company typo p5'>{{company}}</p>
                <p class='trans-type typo p5'>{{transType}}</p>
                <p class='role typo p5'>{{role}}</p>
            </div>
        </div>
    `;


    try {
        let stringOps = window.$methods.stringOps;
        let truncate = window.$methods.stringOps.truncateWithEllipsis;

        $(className).each(function(index, elem){
            let meta = (elem.dataset.meta);
            if(meta){
                let allData = JSON.parse(meta);
                console.log("---- meta: ", allData);

                if(allData.length > 0){
                    let ul = document.createElement("ul");
                    ul.className = "item-container";
                    
                    for(let i = 0; i < allData.length; i++){
                        let data = allData[i];
                        let li = document.createElement("li");
                        li.className = "data-item";

                        if(!data || !data.isValid || !data.info) continue;
                        
                        switch(data.type){
                            case "event":
                            case "article":
                                console.log("--> rendering", data.type);
                                let hostString = (data.type == 'event') ? "Hosted By:" : "By:";
                                let typeString = (data.type == 'event') ? "Event" : "News";
                                let linkString = (data.type == 'event') ? "Learn More" : "Read More";
                                
                                let dataItemA = itemTemplateBasic;
                                dataItemA = dataItemA.replace("{{type}}", typeString);
                                dataItemA = dataItemA.replace("{{title}}", truncate(data.info.title, 80));
                                dataItemA = dataItemA.replace("{{date}}", data.info.date);
                                dataItemA = dataItemA.replace("{{body}}", truncate(data.info.description, 70));
                                dataItemA = dataItemA.replace("{{meta}}", (data.info.person) ? `${hostString} ${data.info.person}`: '');
                                dataItemA = dataItemA.replace("{{url}}", (data.info.url) ? data.info.url : '');
                                dataItemA = dataItemA.replace("{{linkText}}", linkString);
                                dataItemA = dataItemA.replace("{{personDispStyle}}", data.info.person ? "block": "none");
                                dataItemA = dataItemA.replace("{{linkDispStyle}}", data.info.url ? "block": "none");
                                
                                li.innerHTML = dataItemA;
                                ul.appendChild(li);
                            break;

                            case "research_report":
                                console.log("--> rendering", data.type);
                                
                                let dataItemB = itemTemplateResearch;
                                dataItemB = dataItemB.replace("{{type}}", "Research");
                                dataItemB = dataItemB.replace("{{title}}", truncate(data.info.title, 80));
                                dataItemB = dataItemB.replace("{{date}}", data.info.date);
                                dataItemB = dataItemB.replace("{{body}}",'');
                                dataItemB = dataItemB.replace("{{author}}", (data.info.person) ? `Author: ${data.info.person}`: '');
                                dataItemB = dataItemB.replace("{{sector}}", data.info.sector ? data.info.sector : '');

                                //notice a bug, where if the title of the report or the author's name contained a quotation mark
                                //it would break the string inside the "onclick" attribute below. So just eliminating all quotation
                                //marks from these two strings. These two strings are then dumped into a list and stringified to
                                //later serve as the second argument for the handleRequest function
                                let _titleArg = stringOps.removeQuotes(data.info.title);
                                let _authorArg = stringOps.removeQuotes(data.info.person);
                                let arg = JSON.stringify([_titleArg, _authorArg]);

                                dataItemB = dataItemB.replace("{{url}}", `javascript:window.$methods.handleRequest("research", ${arg})`);
                                dataItemB = dataItemB.replace("{{linkText}}", "Request Access");
                                dataItemB = dataItemB.replace("{{authorDispStyle}}", data.info.person ? "block": "none");
                                dataItemB = dataItemB.replace("{{sectorDispStyle}}", data.info.sector ? "block": "none");
                                
                                li.innerHTML = dataItemB;
                                ul.appendChild(li);
                            break;


                            case "transaction":
                                console.log("--> rendering", data.type);

                                let dataItemC = itemTemplateTransaction;
                                dataItemC = dataItemC.replace('{{logo}}', data.info.logo);
                                dataItemC = dataItemC.replace('{{amount}}', data.info.amount);
                                dataItemC = dataItemC.replace('{{date}}', data.info.date);
                                dataItemC = dataItemC.replace('{{company}}', data.info.company);
                                dataItemC = dataItemC.replace('{{transType}}', data.info.transType.replace('+','&'));
                                dataItemC = dataItemC.replace('{{role}}', data.info.role);

                                li.innerHTML = dataItemC;
                                ul.appendChild(li);
                            break;
                            

                            default:
                            break;
                        }

                    }

                    elem.appendChild(ul);
                }
            }
        });
    }
    catch (err) {
        console.warn("cmp_blade_title: ", err);
    }
})();

(function(){
    console.log("----> cmp_icon_button_block");
})();

(function(){
        try {
            //code here
        }
        catch (err) {
            console.warn("cmp_sector_end: ", err);
        }

           
    })();

(function(){
    try {
        console.log('----> cmp_people_feature');
        let adjustParagraph = function(){
            /**
            * tiles have a fixed height to create a grid. 
            * since the person's name can take up more than one line
            * the role has to adjust to the remaining space.
            */
            let personTile =  $('.person_tile').innerHeight();
            let profilePicture =  $('.profile-pic').outerHeight(true);
            let personName =  $('.person-name').outerHeight() ;
            let remainingSpace = personTile - profilePicture - personName;
            let fittingLines = (remainingSpace > 0 )? Math.floor(remainingSpace / 24) : 0 ;

            switch(true){
                case (fittingLines == 1):
                    $('.person-role').addClass('one-line');
                    break;
                case (fittingLines > 1) :
                    $('.person-role').css('height', function(){
                        return fittingLines * 24 + 'px';
                    });
                    $('.person-role').addClass('fakeEllipsis');
                    break;
                default: 
                    $('.person-role').addClass('hidden');
            }
        
        }
        let equalHeight = function(){
            let maxHeight = 0;
            $('.person_tile').each(function(){
                let thisHeight = $(this).outerHeight();
                console.log('thisHeight', thisHeight);
                maxHeight = (thisHeight > maxHeight)? thisHeight : maxHeight;
            });
            if (maxHeight > 0) $('.person_tile').css('height', maxHeight+'px');
        }

        let setWidth = function(){
            let winWidth = $(window).width();
            $('.cmp_people_feature').each(function(){
                let innerPeople = $(this).find('.person_tile');
                let peopleNum = innerPeople.length;
                if(peopleNum < 4){
                    if (winWidth > 1000 || winWidth > 420  && peopleNum < 3 || winWidth < 480 && peopleNum < 2 ) {
                        $(this).css('width', function(){ return peopleNum * 240 });
                        if(winWidth < 420 && peopleNum < 2) $(innerPeople).css('width', 240 );
                    }
                }

                if(peopleNum == 1){
                    $(innerPeople).css('margin-left', '50%');
                    $(innerPeople).css('transform', 'translateX(-50%)');
                }
            });
        }
        setWidth();
        $(window).on('resize', function(){
            setWidth()
        });
    }
    catch (err) {
        console.warn("cmp_people_feature: ", err);
    }

})();

(function(){
        const className = ".person_tile > a";
        try {
            $(className).each(function(index, elem){
                let link = elem;
                
                //if this link's href is invalid or is the same as the current page, then remove it
                if(!link.href || typeof(link.href) != typeof('') || link.href.trim().length == 0 || link.href == window.location.href){
                    //the href attribute is absent. No bio for this person
                    elem.removeAttribute("href");
                }
            });
        }
        catch (err) {
            console.warn("person_icon_block: ", err);
        }
    })();

(function(){
    try {
        //console.log("----> cmp_reduced_hero");
    }
    catch (err) {
        console.warn("cmp_reduced_hero: ", err);
    }
})();

(function(){
    /*
    * https://t.modusagency.com/youtrack/issue/CHRM-9
    * In order to add a custom rich text "button-link" style to this component, we had to:
    * -- create a custom label (ie. "button-link") to the prismic definition of this slice
    * -- this would create a "<span class='button-link'>" around any element that this label is applied to
    * -- The ideal use case is around links
    * 
    * When this label is applied to a link in the rich text box, it renders html like this:
    * <span class='button-link'><a href='http://someurl'></span>
    * 
    * Now we've added css that makes this entire span look like a regular button. In this case, the <a> is
    * already navigable, but the surrounding <span> is not. the span element needs to be made "navigable". 
    * 
    * So when the user clicks any part of the button (<span> or <a>), the navigation takes place. In order
    * to do this, we grab all the button link elements inside the success story > rich text blocks and attach
    * click event listeners to all of them that will navigate to the links in the <a> inside them.
    */
    try {
        let cName = ".cmp_success_story .rich-text .button-link";
        $(cName).each((index, elem) => {

            if(elem && elem.children.length > 0 && elem.firstChild && elem.tagName.toLowerCase().trim() == "a"){
                let anchor = elem.firstChild;
                let link = anchor.href;

                if(link && link.trim().length > 0){
                    elem.addEventListener('click', () => window.location.href = link);
                }
            }
        });
    }
    catch (err) {
        console.warn("cmp_success_story: ", err);
    }
})();

(function(){
    try {
        //console.log("----> cmp_philanthropy");
    }
    catch (err) {
        console.warn("cmp_philanthropy: ", err);
    }
})();

(function(){
    try {
        //console.log("----> cmp_work_at_chardan");
    }
    catch (err) {
        console.warn("cmp_work_at_chardan: ", err);
    }
})();

(function(){
    try {
        console.log("----> cmp_list_feature");

        let el = $('.cmp_list_feature');
        let id_container = el.find("#uniqueId");
        
        $.each(id_container, (key, val)=>{
            let context = $(val).parent();
            
            let list = $('ul', context),
            rt = $('.rich-text', context),
            listItem = $('li', list),
            listLenght = list.children().length,
            topOffset = 30,
            amountInColumn = listLenght/2,
            ulPadding = 70,
            e = 0,
            listTemplate = $('<ul></ul>');

            context.find('#uniqueId').remove();

            const addItemToNewList = (item) => {
            if(listTemplate.children().length == 0){
                    listTemplate.insertAfter(list, rt);
            }
            
            listTemplate.append(item);
            };

            for(let i = 0; i < listLenght; i++) {
                if (i >= amountInColumn) {
                    addItemToNewList(listItem[i]);
                    e++;
                }
            }
        });
    }
    catch (err) {
        console.warn("cmp_list_feature: ", err);
    }

})();

(function(){
    console.log('!! cmp_recent_content !!' )
    const className = ".cmp_recent_content";

    const itemTemplateBasic = `
        <div class='lined-box event-article'>
            <div class='lines top'>
                <div class='line'></div>
                <div class='line'></div>
                <div class='line'></div>
            </div>
        </div>
        <div class='content'>
            <p class='type typo h5'>{{type}}</p>
            <p class='title typo h4'>{{title}}</p>
            <p class='date typo p5'>{{date}}</p>
            <p class='body typo p5'>{{body}}</p>
            <p class='meta typo p5' style='display:{{personDispStyle}}'>{{meta}}</p>
            <a href='{{url}}' style='display:{{linkDispStyle}}'><p class='link typo p5'>{{linkText}}</p></a>
        </div>
    `;

    const itemTemplateResearch = `
        <div class='lined-box'>
            <div class='lines top'>
                <div class='line'></div>
                <div class='line'></div>
                <div class='line'></div>
            </div>
        </div>
        <div class='content research'>
            <p class='type typo h5'>{{type}}</p>
            <p class='title typo h4'>{{title}}</p>
            <p class='date typo p5'>{{date}}</p>
            <div class='author-sector-cont'>
                <p class='meta typo p5' style='display:{{authorDispStyle}}'>{{author}}</p>
                <p class='meta typo p5' style='display:{{sectorDispStyle}}'>{{sector}}</p>
            </div>
            <a href='{{url}}' ><p class='link typo p5'>{{linkText}}</p></a>
        </div>
    `;

    const itemTemplateTransaction = `
        <div class='lined-box'>
            <div class='lines top'>
                <div class='line'></div>
                <div class='line'></div>
                <div class='line'></div>
            </div>
        </div>
        <div class='content transaction'>
            <img src='{{logo}}'></img>
            <p class='title typo h4'>{{amount}}</p>
            
            <div class='transaction-info-cont'>
                <p class='date typo p5'>{{date}}</p>
                <p class='company typo p5'>{{company}}</p>
                <p class='trans-type typo p5'>{{transType}}</p>
                <p class='role typo p5'>{{role}}</p>
            </div>
        </div>
    `;

    try {
        let stringOps = window.$methods.stringOps;
        let truncate = window.$methods.stringOps.truncateWithEllipsis;
        let getData = window.$methods.filters.getData;
        let actualPage;

        /**
         *  loadingModal()
         *  Shows or hides the loading overlay
         *  @param {Boolean} state
         */ 
        const toggleLoadingModal = (state, container) => {
            let context = $(container).parent();
            let loadingModal = context.find('.loading');
            if (state) loadingModal.addClass('active');
            else setTimeout(() => { loadingModal.removeClass('active') }, 200);   
        }
 
        /**
         *  scrollToFirst()
         *  srolls to the first item of the new result list.
         *  @param {*} context
         */ 
        const scrollToFirst = (lastItem) => {
            let lastHeight = lastItem.outerHeight() / 2;
            let lastItemPos = lastItem.offset().top - lastHeight;
            $('html, body').animate({
                scrollTop: lastItemPos
            }, 'slow');
        }

        const resolveCurrency = (sUnit) => {
            sUnit = sUnit.toLowerCase();
            let final = '';
            
            switch(sUnit){
                case "us": final = '$'; break;
                case "gb": final = '£'; break;
                case "eur": final = '€'; break;
            }

            return final;
        }

        const parseData = (data, count, type) => {

            let result = [];

            for (let i = 0; i < count; i++) {
                let item = data.items[0];
                let _type = type.toLowerCase();
                let _details = item['data'] ? item['data'] : item || null;
                let final = {type: _type, info: {}, isValid: false};

                if (_type && _details) {
                    switch (_type){
                        case "transaction": 
                            
                            final.info.logo = stringOps.getProp(_details, 'company_logo');
                            final.info.amount = resolveCurrency(_details.currency_unit) + " " + _details.amount + " million";
                            final.info.date = window.$methods.formatDate(_details.date)
                            final.info.company = truncate(_details.company, 100);
                            final.info.transType = _details.type;
                            final.info.role = _details.role;
                            final.isValid = true;

                            if (_details.amount == 0) final.info.amount = '';
                        break;

                        case "event": 
                            let dsc = stringOps.getProp(_details, 'event_body[0].text');
                            let personId = stringOps.getProp(_details, 'host.id');
                            
                            final.info.person = (personId) ? stringOps.getProp([personId], 'full_name[0].text') : 'Chardan Capital';
                            final.info.title = stringOps.getProp(_details, 'event_title[0].text');
                            final.info.date = window.$methods.formatDate(item.date);
                            final.info.description = (dsc) ? truncate(dsc, 140) : '';

                            _details.link_type = "Document";
                            final.info.url = item.actual_url;
                            final.isValid = true;
                        break;

                        case "article": 
                            
                            let aDsc = stringOps.getProp(_details, 'body_copy[0].text');
                            let pId = stringOps.getProp(_details, 'author.id');
                            let showDate = stringOps.getProp(_details, 'show_date');
                            
                            final.info.person = (pId) ? stringOps.getProp([pId], 'full_name[0].text') : 'Chardan Capital';
                            final.info.title = stringOps.getProp(_details, 'article_title[0].text');
                            final.info.date = (showDate == "no") ? '': window.$methods.formatDate(item.date);
                            final.info.description = (aDsc) ? truncate(aDsc, 140) : '';

                            _details.link_type = "Document";
                            final.info.url = item.actual_url;
                            final.isValid = true;
                        break;

                        case "research": 
                            final.info.person = _details.analyst || 'Chardan';
                            final.info.title = _details.title;
                            final.info.date = window.$methods.formatDate(_details.date);
                            final.info.sector = _details.sector;
                            final.isValid = true;
                        break;

                        default: break;
                    }
                }

                if (final.isValid == true) {
                    result.push(final);
                    data.items.shift();
                }
            }

            return result;
        }
       
        const loadMoreButton = (hasLoadMore, container, data, response) => {

            let page = 0,
                totalPages = Math.ceil(response.count / data.items_count);

            if(hasLoadMore.toLowerCase() == "yes") {
                let btnWrapper = $('<div class="btnWrapper"></div>');
                let btn = $('<div class="button primary loadMore">Load more</div>');

                btnWrapper.append(btn);
                $(container).after(btnWrapper);   

                btn.on('click', (e) => {
                    page++;
                    if ((totalPages - 1) == page) {
                        btn.off('click');
                        btn.addClass('disabled');
                    } 

                    toggleLoadingModal(true, container);
                    loadMoreHandler(data, response, container, page);
                });
            }
        }

        const loadMoreHandler = (data, response, container, page) => {
            
            let { type, items_count, load_more, actual_author } = data;
            let nextPage = page;
    
            items_count = response.items.length < items_count ? response.items.length : items_count;

            let allData = parseData(response, items_count, type);

            if (allData.length > 0) {
                markupCreation(allData, container);
                toggleLoadingModal(false, container);
                scrollToFirst($(container).find('li').last())
            }
                  
        }

        const markupCreation = (allData, ul) => {
            for (let i = 0; i < allData.length; i++) {
                let data = allData[i];
                let li = document.createElement("li");
                li.className = "data-item";

                if(!data || !data.isValid || !data.info) continue;
                
                switch(data.type) {
                    case "event":
                    case "article":
                        console.log("--> rendering", data.type);
                        let hostString = (data.type == 'event') ? "Hosted By:" : "By:";
                        let typeString = (data.type == 'event') ? "Event" : "News";
                        let linkString = (data.type == 'event') ? "Learn More" : "Read More";
                        
                        let dataItemA = itemTemplateBasic;
                        dataItemA = dataItemA.replace("{{type}}", typeString);
                        dataItemA = dataItemA.replace("{{title}}", truncate(data.info.title, 80));
                        dataItemA = dataItemA.replace("{{date}}", data.info.date);
                        dataItemA = dataItemA.replace("{{body}}", truncate(data.info.description, 70));
                        dataItemA = dataItemA.replace("{{meta}}", (data.info.person) ? `${hostString} ${data.info.person}`: '');
                        dataItemA = dataItemA.replace("{{url}}", (data.info.url) ? data.info.url : '');
                        dataItemA = dataItemA.replace("{{linkText}}", linkString);
                        dataItemA = dataItemA.replace("{{personDispStyle}}", data.info.person ? "block": "none");
                        dataItemA = dataItemA.replace("{{linkDispStyle}}", data.info.url ? "block": "none");
                        
                        li.innerHTML = dataItemA;
                        $(ul).children().length > 0 ? $(ul).find('li').last().after(li) : ul.appendChild(li);
                    break;

                    case "research":
                        console.log("--> rendering", data.type);
                        
                        let dataItemB = itemTemplateResearch;
                        dataItemB = dataItemB.replace("{{type}}", "Research");
                        dataItemB = dataItemB.replace("{{title}}", truncate(data.info.title, 80));
                        dataItemB = dataItemB.replace("{{date}}", data.info.date);
                        dataItemB = dataItemB.replace("{{body}}",'');
                        dataItemB = dataItemB.replace("{{author}}", (data.info.person) ? `Author: ${data.info.person.full_name}`: '');
                        dataItemB = dataItemB.replace("{{sector}}", data.info.sector ? data.info.sector : '');

                        //notice a bug, where if the title of the report or the author's name contained a quotation mark
                        //it would break the string inside the "onclick" attribute below. So just eliminating all quotation
                        //marks from these two strings. These two strings are then dumped into a list and stringified to
                        //later serve as the second argument for the handleRequest function
                        let _titleArg = stringOps.removeQuotes(data.info.title);
                        let _authorArg = stringOps.removeQuotes(data.info.person);
                        let arg = JSON.stringify([_titleArg, _authorArg.full_name]);

                        dataItemB = dataItemB.replace("{{url}}", `javascript:window.$methods.handleRequest("research", ${arg})`);
                        dataItemB = dataItemB.replace("{{linkText}}", "Request Access");
                        dataItemB = dataItemB.replace("{{authorDispStyle}}", data.info.person ? "block": "none");
                        dataItemB = dataItemB.replace("{{sectorDispStyle}}", data.info.sector ? "block": "none");
                        
                        li.innerHTML = dataItemB;
                        $(ul).children().length > 0 ? $(ul).find('li').last().after(li) : ul.appendChild(li);                        
                    break;


                    case "transaction":
                        console.log("--> rendering", data.type);

                        let dataItemC = itemTemplateTransaction;
                        dataItemC = dataItemC.replace('{{logo}}', data.info.logo);
                        dataItemC = dataItemC.replace('{{amount}}', data.info.amount);
                        dataItemC = dataItemC.replace('{{date}}', data.info.date);
                        dataItemC = dataItemC.replace('{{company}}', data.info.company);
                        dataItemC = dataItemC.replace('{{transType}}', data.info.transType.replace('+','&'));
                        dataItemC = dataItemC.replace('{{role}}', data.info.role);

                        li.innerHTML = dataItemC;
                        $(ul).children().length > 0 ? $(ul).find('li').last().after(li) : ul.appendChild(li);
                        
                    break;
                    

                    default:
                    break;
                }
            }
        }

        $(className).each((index, elem) => {
            let meta = (elem.dataset.meta);
            if (meta) {
                let data = JSON.parse(meta);

                if (data) {

                    let { type, items_count, load_more, actual_author } = data;
                    let param = actual_author ? {author:actual_author} : {};

                    getData(type, param).then(response => {
                        let allData = [];
  
                        if (response.count > 0) {
                            allData = parseData(response, items_count, type);
                        } else {
                            $(elem).addClass('no-items');
                            console.log("No items were found")
                        }

                        if (allData.length > 0) {
                            var ul = document.createElement("ul");
                            ul.className = "item-container";
                            
                            markupCreation(allData, ul);
                            
                            elem.appendChild(ul);
                            loadMoreButton(load_more, ul, data, response);
                        }
                    });
                    
                }
            }
        });
    }
    catch (err) {
        console.warn("cmp_recent_content: ", err);
    }

})();

(function(){
    try {
        const stringOps = window.$methods.stringOps;
        const debounce = window.$methods.debounce;

        /**
         * animate(...)
         * the initial animation sequence for the carousel
         */
        var animate = (elem) => {
            let curtain = elem.querySelector(".splash-slide");
            let carousel = elem.querySelector(".carousel-container");
            let contentBox = elem.querySelector(".content-container");
            let controls = elem.querySelector(".controls");
            
            const millisToFirst = 2000;
            const millisDelta = 600;

            if(curtain){

                //shrink
                setTimeout(() => contentBox.classList.add("shrink"), millisToFirst);

                //raise the curtain
                setTimeout(() => curtain.classList.add('rise-up'), millisToFirst + millisDelta);
                
                //fade the carousel contents in
                setTimeout(() => {
                    carousel.style.opacity = 1;
                    controls.style.opacity = 1;
                }, millisToFirst + millisDelta);
                
                //slide left
                setTimeout(() => contentBox.classList.add("slide"), millisToFirst + (2 * millisDelta));
                
            }
            else if(carousel){
                carousel.style.opacity = 1;
                controls.style.opacity = 1;
            }
        };

        /**
         * setupCarouselItems(...)
         * initialize the carousel using the data passed into the 'data-json' attribute. This is 
         * where the json data is turned into carousel items markup and injected into the carousel
         * container.
         */ 
        var setupCarouselItems = (elem, items) => {
            let carousel = elem.querySelector(".carousel-container");
            
            //if this is a valid dom node, proceed with injecting the carousel items
            if(carousel){

                //inner html for a single carousel item
                const itemTemplate = `
                        <div class='left' style='background:url("%url") no-repeat; background-size: cover; background-color:lightgray'></div>
                        <div class='right'>
                            <div class='title typo h3'>%title</div>
                            <div class='descr typo p3'>%descr</div>
                            <a href="%btnUrl"><div class='button primary' style='display:%btnVis'>%label</div></a>
                        </div>
                `;
                
                //create a document fragment
                let frag = document.createDocumentFragment();
                
                //loop through the json data and populate the markup above with the appropriate content
                //and add that markup to the fragment
                for(let i = 0; i < items.length; i++){
                    let item = items[i];
                    let btnVisib = (item.buttonCaption && item.buttonCaption.trim().length > 0 && item.buttonLink && item.buttonLink.trim().length > 0) ? "inline-block" : "none";
                    let outerNode = document.createElement("div");
                    outerNode.className = "carousel-item";
                    outerNode.innerHTML = itemTemplate.replace("%url", item.image)
                                                    .replace("%title", item.title).replace("%descr", item.tagline)
                                                    .replace("%label", item.buttonCaption).replace("%btnVis", btnVisib)
                                                    .replace("%btnUrl",item.buttonLink);
                    outerNode.style.left = (i * 100) + "%";
                    frag.appendChild(outerNode);
                }

                if(items.length <= 1){
                    let contentContainer = elem.querySelector(".content-container");
                    contentContainer.classList.add("no-controls");
                }
                
                //add this fragment to the main carousel dom node
                carousel.appendChild(frag);
                
                //very important, attach a current index parameter to this element
                elem.currentIndex = 0;
                elem.numItems = items.length;

                //update the counter info
                updateCounter(elem);

                //let's attach event handlers
                let arrowLeft = elem.querySelector("#arrow-left");
                let arrowRight = elem.querySelector("#arrow-right");
                arrowRight.addEventListener("click", debounce(slideNext, 300, elem));
                arrowLeft.addEventListener("click", debounce(slidePrev, 300, elem));
            }
        };

        /**
         * slideNext(..)
         * causes the carousel to move to the next slide if one exists
         */ 
        var slideNext = (event, elem) => {
            if(elem.currentIndex + 1 >= elem.numItems){
                return;
            }
            else{
                let carouselItems = elem.querySelectorAll(".carousel-item");
                for(let i = 0; i < carouselItems.length; i++){
                    let curLeft = parseInt(carouselItems[i].style.left);
                    let newLeft = curLeft - 100;
                    carouselItems[i].style.left = newLeft + "%";
                }

                elem.currentIndex++;
                updateCounter(elem);
            }
        };

        /**
         * slidePrev(...)
         * causes the carousel to move to the prev slide if one exists
         */ 
        var slidePrev = (event, elem) => {
            if(elem.currentIndex - 1 < 0){
                return;
            }
            else{
                let carouselItems = elem.querySelectorAll(".carousel-item");
                for(let i = 0; i < carouselItems.length; i++){
                    let curLeft = parseInt(carouselItems[i].style.left);
                    let newLeft = curLeft + 100;
                    carouselItems[i].style.left = newLeft + "%";
                }

                elem.currentIndex--;
                updateCounter(elem);
            }
        };

        /**
         * updateCounter(..)
         * update the slide counter elements - current slide / total number
         */ 
        var updateCounter = (elem) => {
            let curr = elem.querySelector("#current");
            let tot = elem.querySelector("#total");
            curr.innerHTML = stringOps.pad(elem.currentIndex + 1, 2);
            tot.innerHTML = stringOps.pad(elem.numItems, 2);
            adjustArrowState(elem);
        };

        /**
         * adjustArrowState(...)
         * based on the current index, determine which arrow needs to be dimmed
         * when the carousel reaches its ends, the respective arrow is dimmed slightly
         */ 
        var adjustArrowState = (elem) => {

            let arrowRight = elem.querySelector("#arrow-right");
            let arrowLeft = elem.querySelector("#arrow-left");

            arrowLeft.style.opacity = 1;
            arrowRight.style.opacity = 1;

            //no more slides ahead, disable right arrow
            if(elem.currentIndex + 1 >= elem.numItems){
                arrowRight.style.opacity = 0.5;
            }
            else if(elem.currentIndex - 1 < 0){
                arrowLeft.style.opacity = 0.5;
            }
        }

        /**
         * main(...)
         * Run this over every carousel on the page
         */ 
        $(".cmp_hero_carousel").each(function(index, elem){
            let attr = elem.dataset.json;
            let items = JSON.parse(attr);
            
            if(items && items.length > 0 && typeof(items) == typeof([])){
                setupCarouselItems(elem, items);
                animate(elem);
            }
        });
    }
    catch (err) {
        console.warn("cmp_hero_carousel: ", err);
    }

})();

(function(){
    try {
    }
    catch (err) {
        console.warn("quote_with_person: ", err);
    }
})();

(function(){
        try {
            //console.log("----> cmp_button_block_2");
        }
        catch (err) {
            console.warn("cmp_button_block_2: ", err);
        }
        })();

(function(){
    try {
        console.log('cmp_leadership_bio');

        let socialNetworks = ['twitter', 'linkedin'];
        let context = $('.cmp_leadership_bio');

        $("#shareLeadershipBio").jsSocials({
            showLabel: false,
            showCount: false,
            shares: socialNetworks
        });

        let overrideMediaLinks = function(){
            for(let index in socialNetworks ){
                let media = socialNetworks[index];
                let link = $('#shareLeadershipBio').data( media );
                if(link){
                    $('.jssocials-share-'+media+' a', '#shareLeadershipBio').attr('href', link);
                }else{
                    $('.jssocials-share-'+media, '#shareLeadershipBio').remove();
                }
            }
        }
        overrideMediaLinks();
    }
    catch (err) {
        console.warn("cmp_leadership_body: ", err);
    }
   
})();

(function(){
    try {
        //console.log("----> cmp_stat_block_3up");
    }
    catch (err) {
        console.warn("cmp_stat_block_3up: ", err);
    }
})();

(function(){
        try {
            let el = $('.cmp_filter_content');
            let filterType = el.prop('className').split(' ')[1].trim();
            let context = $(`.${filterType}`);
            let loadMore = $('.loadMore', context);
            let applyFilters = $('.applyFilters', context);
            let results = $('.results', context);
            let loadingModal = $('.loading', context);
            let filtersWrapper = $('.filters-wrapper', context);
            let sorting = $('.sorting-drop', context);
            let filtersDrop = $('.filters-drop', context);
            let optionsContainer = $('.optionsContainer', context);
            let cancel = $('.cancel', context);
            let categoryHeadline = $('.categoryHeadline', context);
            let filtersDrawer = $('.filters-drawer', context);
            let onScreen = 0;
            let totalCount = 0;
            let itemsPerPage = (filterType == 'transactions')? 9: 10;
            let firstLoad = true;
            let checkboxGroups = [];
            let query = [];
            let filtersSelected = [];
            let filtersApplied = {};
            let data;

            
            const constants = {
                EMPTY: 'empty',
                NO_MORE: 'nomore'
            };
            
            /**
             *  filter()
             *  This method makes a calls to the API through the Filter class
             *  and returns the filtered data.
             *  query -> returns the first 20 filtered values
             *  no query -> returns the first 20 values
             *  @param {args} args filter query (optional)
             */
            const filter = (args) => {
                return new Promise((resolve, reject) => {
                    if(firstLoad || args != undefined) {
                        window.$methods.filters.getData(filterType, args)
                        .then(response => {
                            createFilters(response.filters, firstLoad);
                            onScreen = 0;
                            firstLoad = false;
                            totalCount = response.count;
                            data = response;
                            resolve(getMore(data));
                        }).catch(err => {
                            reject(err);
                        });
                    } else {
                        resolve(getMore(data));
                    }
                });
            }
    
            /**
             *  getMore()
             *  This method is used by the [ filter method ]
             *  It gets the 20 first items and so on... 
             *  @param {Array} data 
             */
            const getMore = (data) => {
                let noMore;
                if (data.count == 0) return constants.EMPTY;
                if ((onScreen + itemsPerPage) >= totalCount) noMore = constants.NO_MORE;
                let _data = JSON.parse(JSON.stringify(data.items));
                let result = _data.slice(onScreen, onScreen + itemsPerPage);
                onScreen = onScreen + itemsPerPage;
                return {data:result, status:noMore};
            }

             /**
             *  createFilters()
             *  Dinamicaly creats the filters based on the data from the API
             *  @param {Array} filters
             *  @param {Boolean} firstLoad
             */ 
            const createFilters = (filters, firstLoad) => {
                if (firstLoad == false) return;
                for(let val in filters) {
                    let group = val;
                    checkboxGroups.push(group);
                    createFilterBox(filters[val], group, val);
                }
                addEvents();
            }
            
            /**
             *  createFilterBox()
             *  Dinamicaly creats the filter box based on the data from the API
             *  @param {Array} items
             *  @param {String} group
             *  @param {String} val
             */ 
            const createFilterBox = (items, group, val) => {

                let box = $(`<div class="filterCategory ${group}"></div>`);
                let boxHeadline = $(`<h5 class="typo h5 categoryHeadline">${val}</h5>`);
                let optionsContainer = $('<div class="optionsContainer"></div>');
                box.append(boxHeadline);
                
                items.forEach(item => {

                    let val = '',
                        label = '';

                    if (typeof item === "object") {
                        val = item.values;
                        label = item.label;
                    } else {
                        val = item;
                        label = item;
                    }

                    let boxItem = $(`
                        <label>
                            <input type="checkbox" value="${val}" name="${group}" />
                        ${label}
                        </label>
                    `);
                    optionsContainer.append(boxItem);
                });
            
                box.append(optionsContainer);
                filtersWrapper.append(box);
            }

            /**
             *  addClearFiltersButton()
             *  Adds the clear button and event for the selected filter box
             *  on click it removes all the filters applied to that box and runs the query
             *  @param {String} name
             */ 
            const addClearFiltersButton = (name) => {
                let target = $(`.filterCategory.${name}`, context);
                let clear = target.find(`.clear-${name}`);
                let button;

                if(clear.length == 0) {
                    button = $(`<div class="button link1 clear-filters-button clear-${name}" data-name=${name}>Clear filters</div>`);
                    target.prepend(button);
                
                    button.on('click', (e) => {
                        let name = $(e.currentTarget).data('name');
                        clearFilters(target);
                        if(!anyFilterActive()) applyFilters.addClass('disabled');
                        removeClearButton(name);
                        if(filtersApplied[name] && filtersApplied[name].length > 0) crateQuery();
                    });
                } else {
                    removeClearButton(name);
                }
            }

            /**
             *  removeClearButton()
             *  Removes the clear button from the filter box
             *  @param {String} name
             */ 
             const removeClearButton = (name) => {
                let target = $(`.filterCategory`);
                let clearBtn = target.find(`.clear-${name}`);
                if(!anyFilterActive(name)) clearBtn.remove();
            }
            
            /**
             *  clearFilters()
             *  Clear the selected filters and run the query
             *  @param {String} target (optional)
             */ 
            const clearFilters = (target) => {
                if(target) {
                    $('input[type=checkbox]', target).each((index, val) => {
                        val.checked = false;
                    });
                } else {
                    $('input[type=checkbox]').each((index, val) => {
                        val.checked = false;
                    });
                }

               checkFiltersApplied();
            }

            const checkFiltersApplied = () => {
                let filtersAppliedCopy = JSON.parse(JSON.stringify(filtersApplied));
                delete filtersAppliedCopy['sort'];

                if($.isEmptyObject(filtersAppliedCopy) == false) {
                    crateQuery();
                } 
            }

            /**
             *  anyFilterActive()
             *  checks if any filter is active.
             *  @param {*} context
             */ 
            const anyFilterActive = (context = '') => {
                let _context = context != '' ? `.${context}` : '';
                if ($("input:checked", _context).length > 0) return true;
                return false;
            }

            /**
             *  scrollToFirst()
             *  srolls to the first item of the new result list.
             *  @param {*} context
             */ 
            const scrollToFirst = (lastItem) => {
                let lastHeight = lastItem.outerHeight() / 2;
                let lastItemPos = lastItem.offset().top + lastHeight;
                $('html,body').animate({
                    scrollTop: lastItemPos
                }, 'slow');
            }

            /**
             *  addEvents()
             *  Add the click event to the Load More button.
             */ 
            const addEvents = () => {

                loadMore.on('click', (e) => {
                    let lastItem = $('.result-item').last();
                    searchData();
                    scrollToFirst(lastItem);
                });

                let checkboxs = $('input[type=checkbox]', context);
                checkboxs.each((index, item) => {
                    $(item).on('change', (e) => {
                        let name = $(e.currentTarget).attr('name');

                        if (anyFilterActive()) {
                            applyFilters.removeClass('disabled');
                        } else {
                            applyFilters.addClass('disabled');
                            checkFiltersApplied();
                        }
                        
                        addClearFiltersButton(name);
                    });
                });

                applyFilters.on('click', (e) => {
                    if(applyFilters.hasClass('disabled')) return;
                    toggleFiltersDrawer();
                    setTimeout(()=>{
                        crateQuery();                
                    }, 200);
                });


                sorting.on('change', (e) => {
                    if(sorting.hasClass('disabled')) return;
                    crateQuery()
                });

                
                $('.categoryHeadline').on('click', (e) => {
                    let catContext = $(e.target).closest('.filterCategory');
                    if (catContext.hasClass('openCategory')) {
                        catContext.removeClass('openCategory');
                    } else {
                        catContext.addClass('openCategory');                        
                    }
                });
                
                filtersDrop.on('click', (e) => {
                    toggleFiltersDrawer();
                });

                cancel.on('click', (e)=>{
                    toggleFiltersDrawer();
                    clearFilters();
                });
                
            }

            /**
             *  toggleFiltersDrawer()
             *  Open and close the filters 
             */ 
            const toggleFiltersDrawer = () => {
                if (filtersDrawer.hasClass('hidden') ) {
                    filtersDrawer.removeClass('hidden');
                    setTimeout(() => {
                        $('.filters').css('height', 'auto');
                    }, 200);
                } else {

                    // $('.filterCategory').removeClass('openCategory');     
                    filtersDrawer.addClass('hidden');    
                    setTimeout(() => {
                        $('.filters').css('height', '0px');
                    }, 200);
                    
               
                }
            }
            
            /**
             *  crateQuery()
             *  creates the query from the filters to send to the API
             */ 
            const crateQuery = () => {
                let cBoxGroup = $("input:checked");
                let category = {};
                let query = {};

                filtersApplied = {};
                
                $.each(cBoxGroup, (index, cBox) => {
                    let group = $(cBox).attr('name');
                    
                    if(Object.keys(category).indexOf(group) > -1){
                        category[group] += ($(cBox).val());
                        query[group].push($(cBox).val());
                    } else {
                        category[group] = $(cBox).val();
                        query[group] = [];
                        query[group].push($(cBox).val());
                    }
                });
                
                // NOTE: When we are filtering transactions, the variable query.type
                // contains a list of string that looks likr this -> ["SPAC,SPAC IPO,SPAC PIPE,SPAC M&A", "IPO,SPAC IPO"]
                // This is a list of string where each string shoud further split down to individual strings.
                // The output shoud look like: ["SPAC", "SPAC IPO", "SPAC PIPE", "SPAC M&A","IPO"]
                // Thikngs to Do: Split, dedupe.
                if (filterType == 'transactions' && query['type']) {
                    let outputList = [];
                    let length = query['type'] ? query.type.length : 0;
                    for ( let i = 0; i < length; i++){
                        let listOfStrings = query["type"][i];
                        let lst = listOfStrings.split(',');
                        
                        for ( let e = 0; e < lst.length; e++) {
                            let output = lst[e];

                            // DEDUPE & PUSH
                            if (outputList.indexOf(output) < 0) {
                                outputList.push(output);
                            }
                        }   
                    }
                    query.type = outputList; // ex: ["SPAC", "SPAC IPO", "SPAC PIPE", "SPAC M&A","IPO"]
                }

                filtersApplied = query;
                query.sort = [parseInt(sorting.val())];
                
                console.log("[ [ QUERY ] ] ---> ", query);
                searchData(query);
            }

            /**
             *  loadingModal()
             *  Shows or hides the loading overlay
             *  @param {Boolean} state
             */ 
            const toggleLoadingModal = (state) => {
                if (state) loadingModal.addClass('active');
                else setTimeout(() => { loadingModal.removeClass('active') }, 200);
                
            }
            
            /**
             *  sortingDisabled()
             *  Enable/Disable sorting
             *  @param {Boolean} disabled
             */ 
            const sortingDisabled = (disabled) => {
                if(disabled) {
                    sorting.addClass('disabled');
                    sorting.attr('disabled', 'disabled');
                } else {
                    sorting.removeClass('disabled');
                    sorting.removeAttr('disabled');
                }
            }
            
            /**
             *  isEmptyResult()
             *  Check if the results are empty and enable/disable some features
             *  shows the no results found message
             *  @param {Array} list
             */ 
            const isEmptyResult = (list) =>{
                let isEmpty = false;

                if (list == constants.EMPTY) {
                    results.empty();
                    results.append('<p class="typo h3 no-results-message">No results found.</p>');
                    loadMore.addClass('no-results');
                    isEmpty = true;
                    sortingDisabled(true);
                    toggleLoadingModal(false); // remove the loading after Dom injection.
                    
                } else if (list.status == constants.NO_MORE) {
                    loadMore.addClass('no-results');
                    sortingDisabled(false);
                } else {
                    loadMore.removeClass('no-results');
                    sortingDisabled(false);
                }
                
                return isEmpty;
            }

            /**
             *  searchData()
             *  Searchs the data and inject it to the Dom.
             *  @param {Object} args the filter query (optional)
             */ 
            const searchData = (args) => {

                toggleLoadingModal(true); // show the loading every time we ask for new data.

                filter(args).then(list => {

                    if( isEmptyResult(list) ) return;
                    if (args) results.empty();

                    list.data.forEach((item, index) => {
                        let researchItem = window.$methods.getFilterItemsByType(filterType, item);
                        results.append(researchItem);
                    });

                    toggleLoadingModal(false); // remove the loading after Dom injection.

                });
            }
            
            /**
             *  init()
             *  Entry point.
             */  
            const init = () => {
                searchData();
            }

            init();

        } catch (err) {
            console.warn("cmp_filter_content: ", err);
        }
    })();

(function(){
    const className = ".cmp-featured-carousel";

    const itemTemplateBasic = `
        <div class='content'>
            <p class='title typo h3'><a href="{{url}}">{{title}}</a></p>
            <p class='date typo p5'>{{date}}</p>
            <p class='body typo p5'>{{body}}</p>
        </div>
        <div class="person">
            <img src="{{photo}}" style='display:{{photoDispStyle}}'>
            <div class="person-info">
                <p class='meta typo h4' style='display:{{personDispStyle}}'>{{meta}}</p>
                <p class="role typo p3">{{personRole}}</p>
            </div>
        </div>
    `;

    const itemTemplateResearch = `
        <div class='content research'>
            <p class='title typo h3'><a href="{{url}}">{{title}}</a></p>
            <p class='date typo p5'>{{date}} <span class="sector"> | {{sector}} </span></p>
            <p class='body typo p5'>{{body}}</p>
        </div>
        <div class="person">
            <div class="person-info">
                <p class='meta typo h4' style='display:{{personDispStyle}}'>{{author}}</p>
                <a href='{{url}}' ><p class='link typo p5'>{{linkText}}</p></a>
            </div>
        </div>
    `;


    try {
        let stringOps = window.$methods.stringOps;
        let truncate = window.$methods.stringOps.truncateWithEllipsis;
        
        $(className).each(function(index, elem){
            let meta = (elem.dataset.meta);
            if(meta){
                let allData = JSON.parse(meta);
                console.log("---- meta: ", allData);

                if(allData.length > 0){
                    let ul = document.createElement("ul");
                    ul.className = "item-container";
                    
                    for(let i = 0; i < allData.length; i++){
                        let data = allData[i];
                        let li = document.createElement("li");
                        li.className = "data-item";

                        if(!data || !data.isValid || !data.info) continue;
                        
                        switch(data.type){
                            case "event":
                            case "article":
                                
                                let typeString = (data.type == 'event') ? "Event" : "News";
                                let linkString = (data.type == 'event') ? "Learn More" : "Read More";
                                
                                let dataItemA = itemTemplateBasic;
                                dataItemA = dataItemA.replace("{{type}}", typeString);
                                dataItemA = dataItemA.replace("{{title}}", truncate(data.info.title, 100));
                                dataItemA = dataItemA.replace("{{date}}", data.info.date);
                                dataItemA = dataItemA.replace("{{body}}", truncate(data.info.description, 480));
                                dataItemA = dataItemA.replace("{{meta}}", (data.info.person) ? `${data.info.person}`: '');
                                dataItemA = dataItemA.replace("{{url}}", (data.info.url) ? data.info.url : '');
                                dataItemA = dataItemA.replace("{{linkText}}", linkString);
                                dataItemA = dataItemA.replace("{{personDispStyle}}", data.info.person ? "block": "none");
                                dataItemA = dataItemA.replace("{{linkDispStyle}}", data.info.url ? "block": "none");
                                dataItemA = dataItemA.replace('{{photo}}', data.info.photo ? data.info.photo : null );
                                dataItemA = dataItemA.replace('{{photoDispStyle}}', data.info.photo ? "block": "none");
                                dataItemA = dataItemA.replace('{{personRole}}', data.info.role? data.info.role : null);
                                li.innerHTML = dataItemA;
                                ul.appendChild(li);
                            break;

                            case "research_report":
                                
                                let dataItemB = itemTemplateResearch;
                                dataItemB = dataItemB.replace("{{type}}", "Research");
                                dataItemB = dataItemB.replace("{{title}}", truncate(data.info.title, 100));
                                dataItemB = dataItemB.replace("{{date}}", data.info.date);
                                dataItemB = dataItemB.replace("{{body}}",'');
                                dataItemB = dataItemB.replace("{{author}}", (data.info.person) ? `Author: ${data.info.person}`: '');
                                dataItemB = dataItemB.replace("{{sector}}", data.info.sector ? data.info.sector : '');

                                //notice a bug, where if the title of the report or the author's name contained a quotation mark
                                //it would break the string inside the "onclick" attribute below. So just eliminating all quotation
                                //marks from these two strings. These two strings are then dumped into a list and stringified to
                                //later serve as the second argument for the handleRequest function
                                let _titleArg = stringOps.removeQuotes(data.info.title);
                                let _authorArg = stringOps.removeQuotes(data.info.person);
                                let arg = JSON.stringify([_titleArg, _authorArg]);

                                dataItemB = dataItemB.replace("{{url}}", `javascript:window.$methods.handleRequest("research", ${arg})`);
                                dataItemB = dataItemB.replace("{{linkText}}", "Request Access");
                                dataItemB = dataItemB.replace("{{authorDispStyle}}", data.info.person ? "block": "none");
                                dataItemB = dataItemB.replace("{{sectorDispStyle}}", data.info.sector ? "block": "none");
                                
                                li.innerHTML = dataItemB;
                                ul.appendChild(li);
                            break;

                            default:
                            break;
                        }

                    }

                    elem.appendChild(ul);
                }
            }
        });

        /*----------------------------------------------------------------------------------------------------
         * Slider controllers
        ----------------------------------------------------------------------------------------------------*/
        $('.cmp-featured-carousel').each(function(index, elem){
            let list = $('.item-container', elem);
            let listLength = $('.data-item', elem).length;
            let listItems = $('.data-item', elem);
            let leftBtn = $('.arrow-left', elem);
            let rightBtn = $('.arrow-right', elem);
            elem.left = 0;
            list.css('left', '-'+elem.left+'%');
            list.css('width', 100 * listLength + '%');
            listItems.css('width', `calc( 100% / ${listLength})`);

            if( listLength <= 1 ){
                $('.arrow', elem).remove();
            }

            let disable = function(btn){
                btn.off('click');
                $(btn).prop({ disabled : true});
            }

            let enable = function(btn){
                if(btn.is('.arrow-left')) leftEvt();
                if(btn.is('.arrow-right')) rightEvt();
                $(btn).prop({ disabled : false});
            }

            let leftEvt = function(){
                leftBtn.on('click', function(){
                    if( elem.left >= 100 * listLength - 100 ) enable(rightBtn);
                    ( elem.left >= 100 )? elem.left = elem.left - 100 : disable(leftBtn);
                    if( elem.left == 0  ) disable(leftBtn);
                    list.css('left', '-'+elem.left+'%');
                });
            }
            
            let rightEvt = function(){ 
                rightBtn.on('click', function(){
                    if( elem.left == 0 ) enable(leftBtn);
                    ( elem.left < 100 * listLength - 100 )? elem.left = elem.left + 100 : disable(rightBtn);
                    if( elem.left == 100 * listLength - 100 ) disable(rightBtn);
                    list.css('left', '-'+elem.left+'%');
                });
            }
            
            rightEvt();
        })
        
    }
    catch (err) {
        console.warn("cmp_blade_title: ", err);
    }
})();

(function(){
    try{
        
        $('h1, h2, h3, h4, h5, h6', '.body_copy').each(function(){
            $(this).addClass('typo');
            let hType =  $(this).prop("tagName");
            hType = hType.toLowerCase();
            $(this).addClass(hType);
        });
        
        $("#shareIcons, #shareIconsFooter").jsSocials({
            showLabel: false,
            showCount: false,
            shares: [{
                share:"email",
                logo: '/img/envelope.svg'
            },
            "facebook",  "twitter", "linkedin"]
        });
    }
    catch(err){
        console.warn("cmp_article_body: ", err);
    }
})();

(function(){
        try {
            console.log("----> cmp_dynamic_contact_info");
        }
        catch (err) {
            console.warn("cmp_dynamic_contact_info: ", err);
        }
    })();

(function(){
        try {
            //console.log("----> cmp_full_width_promo");
        }
        catch (err) {
            console.warn("cmp_spac_promo: ", err);
        }
    })();

(function(){

    try {
  
        $("#shareEventsIcons, #shareEventsIconsFooter").jsSocials({
            showLabel: false,
            showCount: false,
            shares: [{
                share:"email",
                logo: '/img/envelope.svg'
                },
                "facebook",  "twitter", "linkedin"]
            });
    }
    catch (err) {
        console.warn("cmp_event_body: ", err);
    }
})();